import React, {useEffect, useState} from "react";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {LinkContainer} from 'react-router-bootstrap';
import {TeamIdentifier, useTeamIdentifiers} from "../hooks/TeamIdentifiers";
import {useRosters, useUsers} from "../hooks/sleeper";
import {AVATAR_DEFAULT_URL_PREFIX, getRosterIdToRosterMap, getUserIdToUserMap} from "../lib/utils/sleeper";
import TeamAvatar from "./TeamAvatar";

/**
 * Alpha sort by display name
 */
function byDisplayName() {
    return (a: TeamIdentifier, b: TeamIdentifier) => a.displayName.localeCompare(b.displayName);
}

interface NavBarTeams {
    displayName: string;
    avatar: string;
}

export default function ApplicationNavbar() {
    const teams = useTeamIdentifiers();
    const users = useUsers();
    const rosters = useRosters();
    const [navBarTeams, setNavBarTeams] = useState<NavBarTeams[]>([]);

    useEffect(() => {
        if (teams.length === 0 || users.length === 0 || rosters.length === 0) {
            return;
        }

        const userIdUserMap = getUserIdToUserMap(users);
        const rosterIdToRostersMap = getRosterIdToRosterMap(rosters);

        let nextNavBarTeams: NavBarTeams[] = teams.sort(byDisplayName()).map(team => {
            let currentRoster = rosterIdToRostersMap.get(team.rosterId);
            if (currentRoster === undefined) {
                throw new Error("Unable to find roster with id " + team.rosterId);
            }
            let currentUser = userIdUserMap.get(currentRoster.owner_id);
            if (currentUser === undefined) {
                throw new Error("Unable to find user with id " + currentRoster.owner_id);
            }

            let avatar = (currentUser.metadata.avatar !== undefined) ? currentUser.metadata.avatar : AVATAR_DEFAULT_URL_PREFIX + currentUser.avatar;


            return {
                displayName: currentUser.display_name,
                avatar: avatar
            };
        });

        setNavBarTeams(nextNavBarTeams);
    }, [teams, users, rosters]);

    return (
        <Navbar bg="dark" variant="dark" expand="md" fixed="top" collapseOnSelect={true}>
            <Container>
                <LinkContainer to="/">
                    <Navbar.Brand>FMFFL</Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <NavDropdown title="Stats" id="basic-nav-dropdown">
                            <LinkContainer to="/bigboard">
                                <NavDropdown.Item>Big Board</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/stu">
                                <NavDropdown.Item>Stuart's Schedule</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="/powerrankings">
                                <NavDropdown.Item>Power Rankings</NavDropdown.Item>
                            </LinkContainer>
                        </NavDropdown>
                        <NavDropdown title="Teams" id="basic-nav-dropdown">
                            {
                                navBarTeams.map(team =>
                                    <LinkContainer to={'/team/' + team.displayName} key={team.displayName}>
                                        <NavDropdown.Item>
                                            <TeamAvatar displayName={team.displayName} avatar={team.avatar} avatarLeft={true} highlight={false}/>
                                        </NavDropdown.Item>
                                    </LinkContainer>)
                            }
                        </NavDropdown>
                        <LinkContainer to="/playoffs">
                            <Nav.Link>Playoffs</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/guillotine">
                            <Nav.Link>Guillotine</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/constitution">
                            <Nav.Link>Constitution</Nav.Link>
                        </LinkContainer>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}